@font-face {
font-family: '__titleFont_881af9';
src: url(/_next/static/media/35d328148735ad9b-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__titleFont_881af9';
src: url(/_next/static/media/6a8ba8707d180031-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__titleFont_881af9';
src: url(/_next/static/media/2b753e56cc9cd730-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__titleFont_Fallback_881af9';src: local("Arial");ascent-override: 95.01%;descent-override: 18.10%;line-gap-override: 0.00%;size-adjust: 106.09%
}.__className_881af9 {font-family: '__titleFont_881af9', '__titleFont_Fallback_881af9'
}.__variable_881af9 {--polySans-font: '__titleFont_881af9', '__titleFont_Fallback_881af9'
}

